export function formValidation(values: any) {
  const email = values.userEmail;
  const firstPartEmail = email.substring(0, email.indexOf("@"));

  const manageremail = values.managerEmail;
  const firstPartManagerEmail = manageremail.substring(
    0,
    manageremail.indexOf("@")
  );

  const errors = {} as any;
  const emailValidation =
    /^[a-zA-Z0-9_.-]+@(?:(?:[a-zA-Z0-9-]+\.)?[a-zA-Z]+\.)?(deloitte)\.com$/;
  const managerEmailValidation =
    /^[a-zA-Z0-9_.-]+@(?:(?:[a-zA-Z0-9-]+\.)?[a-zA-Z]+\.)?(deloitte)\.com$/;
  const wbsCode =
    /^[A-Z]{3}[0-9]{5}-[a-zA-Z0-9-]{2}-[a-zA-Z0-9-]{2}-[a-zA-Z0-9-]{2}-[a-zA-Z0-9-]{4}$/;

  if (values.userName === "") {
    errors.name = "Full Name is required";
  }

  if (values.userEmail === "") {
    errors.userEmail = "Email is required";
  } else if (!emailValidation.test(values.userEmail)) {
    errors.userEmail = "Please enter valid email";
  }

  if (!isNaN(firstPartEmail)) {
    errors.userEmail = "Username of email cannot be a number";
  }

  if (values.managerEmail === "") {
    errors.managerEmail = "Manager Email is required";
  } else if (!managerEmailValidation.test(values.managerEmail)) {
    errors.managerEmail = "Please enter valid email";
  }

  if (!isNaN(firstPartManagerEmail)) {
    errors.managerEmail = "Username of email cannot be a number";
  }

  if (values.projectWbsCode === "") {
    errors.wbsCode = "WBS Code is required";
  } else if (!wbsCode.test(values.projectWbsCode)) {
    errors.wbsCode = "Enter valid WBS Code";
  }

  if (!values.acceptTerms) {
    errors.acceptTerms = "Please agree to the Terms and Conditions to continue";
  }

  return { errors };
}
