import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { LOCALES } from "../../constants/locales";
import AuthService from "../../services/authService";
import { formValidation } from "../Registration/ValidateForm";
import "./Styles/Registration.scss";
import SuccessModal from "./SuccessModal";
interface PricingPlanItem {
  label: string;
  value: string;
}

interface FormValues {
  userName: string;
  userEmail: string;
  managerEmail: string;
  projectWbsCode: string;
  acceptTerms: boolean;
}

interface userData {
  userEmail: string;
  userName: string;
}

const Registration = () => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(
    !!!location?.state?.userData?.userEmail
  );
  const pricingPlan: PricingPlanItem[] = [
    { label: `${LOCALES.averageCostPerUserPerMonth}`, value: "$0.00" },
    { label: `${LOCALES.tokensLimitPerInteraction}`, value: "$0.00" },
  ];
  const [userData, setuserData] = useState<userData>({
    userEmail: location?.state?.userData?.userEmail || "",
    userName: location?.state?.userData?.userName || "",
  });
  const [formValues, setFormValues] = useState<FormValues>({
    userName: userData?.userName,
    userEmail: userData?.userEmail,
    managerEmail: "",
    projectWbsCode: "",
    acceptTerms: false,
  });
  const [errors, setError] = useState({} as any);
  const [modalShow, setModalShow] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  /**
   *
   * Handling form input changes.
   * @param event
   *
   */
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value, type } = event.target;
    let newValue: string | boolean;
    if (type === "checkbox") {
      newValue = (event.target as HTMLInputElement).checked;
    } else {
      newValue = value;
    }

    setFormValues({
      ...formValues,
      [name]: newValue,
    });
  };

  /**
   *
   * Register the user.
   *
   */
  const registerUser = async () => {
    try {
      setIsLoading(true);
      const response = await AuthService.registerUser(formValues);
      if (response.success) {
        setIsLoading(false);
        setModalMessage(response.message);
        setModalShow(true);
      }
    } catch (err) {
      console.error(err);
      setIsLoading(false);
    }
  };

  /**
   *
   * Validate the user authorization.
   *
   */
  const authorizeUser = async () => {
    try {
      const response = await AuthService.verifyUser();
      if (response && response.userEmail && response.userName) {
        const { userEmail, userName } = response;
        setuserData(response);
        setFormValues({
          ...formValues,
          userEmail,
          userName,
        });
        setIsLoading(false);
      } else {
        AuthService.navigateToSSO();
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      console.error(err);
    }
  };

  useEffect(() => {
   authorizeUser();
  }, []);

  /**
   * Handle the submit event.
   * @param event The event.
   *
   */
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let errorsObj = formValidation(formValues);
    setError(errorsObj.errors);
    if (Object.keys(errorsObj.errors).length === 0) {
      // Submit the form data to your backend server.
      registerUser();
    }
  };

  return (
    <>
      <div className="registration-container">
        {/* Left Section  */}
        <div className="left-section">
          <div className="left-section-heading">
            <div>
              <img src="images/amc-logo.svg" alt={LOCALES.amcCodeAssist}></img>
            </div>
            <h1 className="left-heading">{LOCALES.amcCodeAssist}</h1>
          </div>
          <h2 className="left-section-subheading">{LOCALES.pricingPlan}</h2>
          <div>
            <ul>
              {pricingPlan.map((item) => (
                <>
                  <hr></hr>
                  <li key={item.label}>
                    <span className="amc-text-no-wrap">{item.label}</span>
                    <span>{item.value}</span>
                  </li>
                </>
              ))}
            </ul>
          </div>
          <div>
            <img
              src="images/system-cover-registration.svg"
              className="system-cover-img"
              alt="system-cover-img"
            />
            <img
              src="images/system-tool.svg"
              className="system-img"
              alt="system-logo"
            />
          </div>
        </div>

        {/* Right Section  */}
        <div className="right-section">
          <h2 className="heading">{LOCALES.requestAccess}</h2>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="userName">{LOCALES.fullName}</label>
              <div className="input-icon">
                <input
                  type="text"
                  id="userName"
                  name="userName"
                  value={formValues.userName}
                  onChange={handleChange}
                  placeholder="Name"
                />
                <img src="images/username-icon.svg" alt="Dollar Icon" />
              </div>
              {errors?.name && <p className="error">{errors?.name}</p>}
            </div>
            <div className="form-group">
              <label htmlFor="email">{LOCALES.deloitteEmail}</label>
              <div className="input-icon">
                <input
                  type="email"
                  id="email"
                  name="userEmail"
                  value={formValues.userEmail}
                  onChange={handleChange}
                  placeholder="xxxxx@deloitte.com"
                  readOnly
                />
                <img src="images/email-icon-grey.svg" alt="Dollar Icon" />
              </div>
              {errors?.userEmail && (
                <p className="error">{errors?.userEmail}</p>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="managerEmail">{LOCALES.managerEmail}</label>
              <div className="input-icon">
                <input
                  type="email"
                  id="managerEmail"
                  name="managerEmail"
                  value={formValues.managerEmail}
                  onChange={handleChange}
                  placeholder="xxxxx@xxxx.com"
                />
                <img src="images/email-icon-grey.svg" alt="Dollar Icon" />
              </div>
              {errors?.managerEmail && (
                <p className="error">{errors?.managerEmail}</p>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="projectWbsCode">{LOCALES.projectWbs}</label>
              <div className="input-icon">
                <input
                  type="text"
                  id="projectWbsCode"
                  name="projectWbsCode"
                  value={formValues.projectWbsCode}
                  onChange={handleChange}
                  placeholder="Enter Project WBS code"
                />
                <img src="images/alert-icon.svg" alt="Dollar Icon" />
              </div>
              {errors?.wbsCode && <p className="error">{errors?.wbsCode}</p>}
            </div>
            <div className="form-group checkbox-container">
              <input
                type="checkbox"
                id="acceptTerms"
                name="acceptTerms"
                checked={formValues.acceptTerms}
                onChange={handleChange}
              />
              <label htmlFor="acceptTerms">
                {LOCALES.iAgree}
                <span>
                  <b> {LOCALES.termsAndConditions}</b>
                </span>
              </label>
            </div>
            {errors?.acceptTerms && (
              <p className="error">{errors?.acceptTerms}</p>
            )}
            <div className="button-section">
              <button type="submit" className="submit-button">
                {LOCALES.submit}
              </button>
            </div>
          </form>
        </div>

        {/* Success Modal */}
        <SuccessModal show={modalShow} onHide={() => setModalShow(false)}>
          <div>
            <div className="modal-body-sectioneone">
              <img
                src="images/success-icon.svg"
                className="success-icon"
                alt="Registration Success"
              ></img>
            </div>
            <div className="modal-body-sectiontwo">
              <p className="body-text">{modalMessage}</p>
            </div>
          </div>
        </SuccessModal>
      </div>
      {/* Loader */}
      {isLoading ? (
        <div className="registration-loader">
          <Spinner animation="border" />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Registration;
