import { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { LOCALES } from '../../constants/locales';
import HomeService from '../../services/homeService';
import AmcCard from '../common/Card/AmcCard';
import FlowLine from '../common/FlowLine/FlowLine';
import './Styles/Features.scss';

function Features() {
  const [featuresList, setFeaturesList] = useState([]);

  const getFeaturesList = async () => {
    try {
      const response = await HomeService.getFeaturesList();
      setFeaturesList(response || []);
    } catch (err) {
      setFeaturesList([]);
      console.error(err);
    }
  };

  useEffect(() => {
    getFeaturesList();
  }, []);

  return (
    <Container fluid className="feature-container relative" id="features">
      <div className="absolute dots">
        <img src="images/feature-dots.svg" alt="feature" />
      </div>
      <div className="absolute settings">
        <img src="images/feature-settings.svg" alt="feature" />
      </div>
      <Row>
        <Col>
          <div className="pl-7">
            <div className="flex align-items-center">
              <img
                src="images/menu-icon.svg"
                alt="features"
                className="menu-info"
              />
              <h1 className="text-white text-weight-bold">
                {LOCALES.features}
              </h1>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xl={11}>
          <div className="pl-7">
            <div className="flex">
              <div className="feature-list">
                {featuresList.length &&
                  featuresList.map((feature, index) => (
                    <AmcCard
                      key={`feature-${index}`}
                      details={feature}
                      type="features"
                    />
                  ))}
              </div>
            </div>
          </div>
        </Col>
        <Col xl={1}></Col>
        <Col xl={12}>
          <div className="back-footer">
            {/* <BackToTop color="#ffffff" /> */}
          </div>
        </Col>
      </Row>
      <div className="display-inline-flex">
        <FlowLine className="feature-vertical" isDotShow />
        <FlowLine
          className="feature-vertical-2"
          isDotShow={false}
          dotClass="right-check-dot"
        />
      </div>
    </Container>
  );
}

export default Features;
