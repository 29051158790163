import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { useEffect, useRef, useState } from "react";
import { LOCALES } from "../../../constants/locales";
import AMCModal from "../AMCModal/AMCModal";
import "./Styles/AmcCard.scss";

function AmcCard({ details, type }: { details?: any; type?: string }) {
  const [isShowDetailsModal, setIsShowDetailsModal] = useState(false);
  const [lineClamp, setLineClamp] = useState(3);
  const [knowMoreIconPath, setKnowMoreIconPath] = useState(
    "images/right-arrow.svg"
  );
  const [modelIconPath, setModelIconPath] = useState(`images/icons/${type}/${(
                  details?.CODE || details?.code
                )?.toLowerCase()}.svg`);

  const textRef = useRef(null);

  const handleModelDetails = () => {
    setIsShowDetailsModal(true);
  };

  const adjustLineClamp = () => {
    const textElement = textRef.current;
    if (!textElement) return;

    let availableHeight = calculateAvailableHeight(textElement);
    let lineHeight: any = parseInt(
      getComputedStyle(textElement).lineHeight,
      10
    );
    let maxLines = Math.floor(availableHeight / lineHeight);
    setLineClamp(maxLines);
  };

  useEffect(() => {
    window.addEventListener("resize", adjustLineClamp);
    adjustLineClamp();

    return () => {
      window.removeEventListener("resize", adjustLineClamp);
    };
  }, [details.DESCRIPTION]);

  const calculateAvailableHeight = (element: any) => {
    const nextElement = element.nextElementSibling;
    const textRect = element.getBoundingClientRect();
    const nextRect = nextElement.getBoundingClientRect();

    return nextRect.top - textRect.top;
  };

  const onHover = () => {
    if (details.IS_INACTIVE) {
      setKnowMoreIconPath("images/right-arrow-black.svg");
      setModelIconPath(`images/icons/${type}/${(
                  details?.CODE || details?.code
                )?.toLowerCase()}_black.svg`)
    }
  };

  const onHoverLeave = () => {
    if (details.IS_INACTIVE) {
      setKnowMoreIconPath("images/right-arrow.svg");
      setModelIconPath(`images/icons/${type}/${(
        details?.CODE || details?.code
      )?.toLowerCase()}.svg`)
    }
  };

  return (
    <>
      <Card
        className={
          "" +
          (details.IS_INACTIVE
            ? "card-container-inactive"
            : "card-container-active")
        }
        onMouseEnter={(event) => onHover()}
        onMouseLeave={(event) => onHoverLeave()}
      >
        <Card.Body className={type === 'models' ? 'model-card-body' : 'feature-card-body'}>
          <div className="title-header">
            <Card.Title className="title" as="h4">
              {details.IS_INACTIVE ? <div>Coming soon...</div> : ""}
              {details?.DISPLAY_NAME || details.display_name}
            </Card.Title>
            {type !== 'models' ?
            <div className="p-all">
              <img
                src={modelIconPath}
                alt={details?.DISPLAY_NAME || details.display_name}
                onLoad={() => adjustLineClamp()}
              />
            </div> : <></>}
          </div>
          <Card.Text
            className="card-text"
            ref={textRef}
            style={{
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
              WebkitLineClamp: lineClamp,
            }}
          >
            {details?.DESCRIPTION}
          </Card.Text>
          <Button
            className="btn-card"
            variant="outline-light"
            onClick={handleModelDetails}
          >
            {LOCALES.knowMore}
            <img
              id="know-more"
              src={knowMoreIconPath}
              alt={LOCALES.knowMore}
              onLoad={() => adjustLineClamp()}
            />
          </Button>{" "}
        </Card.Body>
      </Card>
      {isShowDetailsModal && (
        <AMCModal
          modaldata={details}
          type={type}
          setisshowdetailsmodal={() => setIsShowDetailsModal(false)}
        />
      )}
    </>
  );
}

export default AmcCard;
