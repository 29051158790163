import { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import { LOCALES } from '../../constants/locales';
import HomeService from '../../services/homeService';
import { Language } from '../../types'; // Import the 'Language' type from the appropriate location
import './Styles/LanguagesBanner.scss';

function LanguagesBanner() {

    const [languages, setLanguages] = useState<Language[]>([]);

    const getLanguages = async () => {
        try {
            const response = await HomeService.getLanguagesList();
            setLanguages(response || []);
        } catch (err) {
            setLanguages([]);
            console.error(err);
        }
    };

    useEffect(() => {
      getLanguages()
    }, []);

  return (
    <Container fluid className="languages-container" id="languages">
      <img src="images/languages-dots.svg" alt="divider-one" className="dots" />
      <div className="languages-sub">
        <div>
          <img src="images/languages-pointer.svg" alt="divider-one" />
        </div>
        <div className="flex align-items-center">
          <img
            src="images/language-user.svg"
            alt="user"
            className="language-user"
          />
          <h1 className="text-white text-weight-bold">{LOCALES.languages} {LOCALES.apiPresent}</h1>
        </div>
      </div>

      <div className="languages-sub-card">
        {languages.map((language, index) => (
          <div key={`language-${index}`} className="language-card">
          <span>{ language.DISPLAY_NAME }</span>
        </div> 
        ))}
      </div>
    </Container>
  );
}

export default LanguagesBanner;
