import "./Styles/Search.scss";

function SearchComponent({ requestData, setSearchResults }: any) {

  const handleSubmit = (event: any) => event.preventDefault();
  const handleSearchChange = (e: any) => {
    if (!e.target.value) return setSearchResults(requestData);

    const resultsArray = requestData.filter(
      (data: any) =>
        data.user_name.toLowerCase().includes(e.target.value) ||
        data.user_email.toLowerCase().includes(e.target.value) ||
        data.project_wbs.toLowerCase().includes(e.target.value) ||
        data.manager_email.toLowerCase().includes(e.target.value) ||
        data.approval_status.toLowerCase().includes(e.target.value) ||
        data?.comments?.toLowerCase()?.includes(e.target.value)
    );

    setSearchResults(resultsArray);
  };

  return (
    <div className="search-container">
      <form onSubmit={handleSubmit}>
        <input
          className="search"
          type="text"
          id="search"
          onChange={handleSearchChange}
          placeholder="Search..."
          onFocus={() => true}
        />
      </form>
    </div>
  );
}

export default SearchComponent;
