import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';

import { NAV_LINKS } from '../../../constants/nav-links';
import './Styles/Navigation.scss';
import { LOCALES } from '../../../constants/locales';

function NavbarNavigation() {
  return (
    <header className="header" id="home">
      <Navbar
        collapseOnSelect
        expand="xl"
        className="bg-body-tertiary bg-grey"
      >
        <Container className="navBar-container mlr-4" fluid>
          <Navbar.Brand as={Link} to="/" className='amc-nav-logo'>
            <img src="images/logo.svg" alt="Deloitte" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className='nav-bar-container'>
              {NAV_LINKS.map((navLink: any) => (<Nav.Link key={navLink.id} href={navLink.href}>{navLink.title}</Nav.Link>))}
            </Nav>
            <Navbar.Collapse className="justify-content-end">
              <Link className="register-btn text-decoration-none" to={{ pathname: '/register' }}>
                { LOCALES.trailOrRegistration }
              </Link>
            </Navbar.Collapse>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
}

export default NavbarNavigation;
