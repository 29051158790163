import Container from "react-bootstrap/Container";
import { LOCALES } from "../../constants/locales";
import "./Styles/Tutorials.scss";

function Tutorials() {
  return (
    <Container fluid className="tutorials-container" id="tutorials">
      <img src="images/tutorials-dots.svg" alt="divider-one" className="dots" />
      <div className="tutorial-sub">
        <div>
          <img src="images/languages-pointer.svg" alt="divider-one" />
        </div>
        <div className="flex">
          <img
            src="images/tutorials-icon.svg"
            alt="tutorials-icon"
            className="tutoral-icon"
          />
          <h1 className="text-white text-weight-bold">{LOCALES.tutorials}</h1>
        </div>
      </div>

      <div className="video-container">
        <video controls className="amc-video">
          <source src="videos/amc_code_assist_demo.mp4" type="video/mp4"></source>
          Your browser does not support HTML video.
        </video>
      </div>
      <div className="amc-tutorials-footer">
        <div className="back-btn">
          {/* <BackToTop color="#ffffff" /> */}
        </div>
        <div>
          <img
            src="images/tutorials-system-settings.svg"
            alt="feature"
            className="bottom-system"
          />
          <img src="images/tutorials-bottom-dots.svg" alt="divider-one" />
        </div>
      </div>
    </Container>
  );
}

export default Tutorials;
