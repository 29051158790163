import { useState } from "react";
import Container from "react-bootstrap/Container";
import { LOCALES } from "../../constants/locales";
import AMCOffCanvas from "../common/AMCOffCanvas/AMCOffCanvas";
import "./Styles/Differentiators.scss";

const Differentiators = () => {
  const [isDifferentiatorsOffCanvasShow, setIsDifferentiatorsOffCanvasShow] =
    useState(false);

  return (
    <Container
      fluid
      className="amc-diffentiator-container relative"
      id="differentiator"
    >
      <img
        src="images/diffenentiator-up-dots.svg"
        className="absolute up-dots"
        alt="up-dots"
      />
      <img
        src="images/diffenentiator-up-dots.svg"
        className="absolute down-dots"
        alt="down-dots"
      />

      <div className="flex justify-content-between align-items-center title-header">
        <div className="flex align-items-center">
          <img
            src="images/adv-pointer.svg"
            alt="Adv Pointer"
            className="mlr-3"
          />
          <div className="flex mlr-4 amc-diff-title-sub-container">
            <img
              src="images/differentiators.svg"
              alt="differentiators"
              className="amc-diff-icon"
            />
            <h5
              className="text-white text-weight-bold title"
              onClick={() => setIsDifferentiatorsOffCanvasShow(true)}
            >
              {LOCALES.amcCodeAssist} {LOCALES.advantage}
            </h5>
          </div>
        </div>
      </div>
      {isDifferentiatorsOffCanvasShow && (
        <AMCOffCanvas
          type="DIFFERNTIATIROS"
          setAMCOffCanvasShow={setIsDifferentiatorsOffCanvasShow}
        />
      )}
    </Container>
  );
};

export default Differentiators;
