
import './Styles/FlowLine.scss';

const FlowLine = ({ className, isDotShow = true, dotClass = 'left-check-dot' }: { className: string, isDotShow: Boolean, dotClass?: string }) => {

  return (
    <div className={`absolute ${className ? className : 'vertical'}`}>
      {isDotShow ? <img src="images/line-dot.svg" className={`absolute ${dotClass}`} alt="check-point" /> : ''}
    </div>
  ) 
}

export default FlowLine;