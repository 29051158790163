import { useState } from "react";
import Container from "react-bootstrap/Container";
import Dropdown from "react-bootstrap/Dropdown";
import { LOCALES } from "../../constants/locales";
import AuthService from "../../services/authService";
import "./Styles/TitleBanner.scss";
const SERVER_URL = process.env.REACT_APP_API_BASE_URL;

function TitleBanner() {
  const [supportedEditors] = useState([
    { name: LOCALES.intellij, code: "intellij" },
    { name: LOCALES.eclipse, code: "eclipse" },
    { name: LOCALES.vsCode, code: "vscode" },
  ]);

  /**
   * Download plugin.
   */
  const download = async (type: string) => {
    const res = await AuthService.verifyUser();
    const url: string = `${SERVER_URL}/genai/auth/download/plugin/${type}`;
    if (!res) {
      window.location.href = url;
    } else {
      window.location.href = url;
    }
  };

  return (
    <div className="h-40 relative">
      <Container
        fluid
        className="banner-container relative"
        id="title-banner-downloads"
      >
        <div className="img-banner">
          <div>
            <img
              src="images/amc-logo.svg"
              alt="amc-code-assisit"
              className="mb-1"
            />
            <div className="logo-text">{LOCALES.amcCodeAssist}</div>
          </div>
        </div>
      </Container>

      <div className="amc-drop-down-liner">
        <Dropdown className="download-dropdown amc-drop-down">
          <Dropdown.Toggle
            variant="outline-light"
            className="download"
            id="dropdown-basic"
          >
            {LOCALES.downloads}{" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="#ffffff"
              className="bi bi-download icon"
              viewBox="0 0 16 16"
            >
              <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
              <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z" />
            </svg>
          </Dropdown.Toggle>

          <Dropdown.Menu className="download-dropdown-menu">
            {supportedEditors.map((editor) => (
              <Dropdown.Item
                className="download-dropdown-item"
                onClick={() => download(editor.code)}
              >
                {editor.name}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        {/* <Button
          variant="link"
          className="link-plugin text-decoration-none"
        >
          {LOCALES.intellijPluginInstallationGuide}
        </Button> */}
      </div>

      <div className="system-banner">
        <img
          src="images/system-cover.svg"
          className="system-bg-dots absolute"
          alt="system-logo"
        />
        <img
          src="images/system-tool.svg"
          className="img-system absolute"
          alt="system-logo"
        />
      </div>
    </div>
  );
}

export default TitleBanner;
