import Carousel from "react-bootstrap/Carousel";
import Modal from "react-bootstrap/Modal";
import "./Styles/AMCModal.scss";
import { LOCALES } from "../../../constants/locales";
import { CloseButton } from "react-bootstrap";
import { useEffect, useState } from "react";

function createMarkup(dirty: any) {
  return { __html: dirty };
}

const AMCModal = (props: any) => {
  const [modelDescription, setModelDescription] = useState("");
  const [selectedModel, setSelectedModel] = useState("");
  const [selectedModelImagePath, setSelectedModelImagePath] = useState("");

  const selectItem = (model: any) => {
    setSelectedModel(model.CODE);
    setModelDescription(model.SPECIFICATIONS);
    setSelectedModelImagePath(
      `images/icons/${props.type}/${(
        model?.CODE || model?.code
      )?.toLowerCase()}_black.svg`
    );
  };

  const modelIconPath = (model: any) =>
    `images/icons/${props.type}/${(
      model?.CODE || model?.code
    )?.toLowerCase()}.svg`;

  useEffect(() => {
    if (props?.modaldata?.models?.length) {
      selectItem(props?.modaldata?.models[0]);
    }
  }, []);

  return (
    <>
      <Modal
        {...props}
        size="xl"
        show={true}
        backdrop={true}
        aria-labelledby="modal-sizes-title-lg"
        className="amc-modal"
        onHide={props.setisshowdetailsmodal}
      >
        {props.type === "models" ? (
          <Modal.Body className="amc-modal-body amc-model-container">
            <CloseButton
              className="modal-close-btn btn-close-white"
              onClick={props.setisshowdetailsmodal}
            />
            <>
              <div className="amc-modal-content">
                <div className="amc-modal-left-panel">
                  {props.modaldata.models.map((model: any) => (
                    <div
                      className={`model-item ${
                        model.CODE === selectedModel && !model.IS_INACTIVE
                          ? "status-active"
                          : ""
                      } ${
                        model.CODE === selectedModel && model.IS_INACTIVE
                          ? "item-inactive"
                          : ""
                      }`}
                      onClick={() => selectItem(model)}
                    >
                      <div>{model.DISPLAY_NAME}</div>
                      <div className="pl-icon">
                        <img
                          src={
                            (model.CODE === selectedModel &&
                              model.IS_INACTIVE &&
                              selectedModelImagePath) ||
                            modelIconPath(model)
                          }
                        />
                      </div>
                    </div>
                  ))}
                </div>
                <div className="amc-modal-right-panel">
                  <p
                    dangerouslySetInnerHTML={createMarkup(modelDescription)}
                  ></p>
                </div>
              </div>
            </>
          </Modal.Body>
        ) : (
          <Modal.Body className="amc-modal-body">
            <CloseButton
              className="modal-close-btn"
              onClick={props.setisshowdetailsmodal}
            />
            <>
              {props.type === "models" ? (
                <div className="amc-modal-item">
                  <h1 className="amc-modal-title">
                    {LOCALES.models} {LOCALES.apiPresent}
                  </h1>
                  <h4 className="amc-modal-sub-title">
                    {props.modaldata.DISPLAY_NAME}
                  </h4>
                  <p
                    className="amc-modal-desc"
                    dangerouslySetInnerHTML={createMarkup(
                      props.modaldata?.SPECIFICATIONS
                    )}
                  ></p>
                </div>
              ) : (
                <Carousel data-bs-theme="dark">
                  <Carousel.Item>
                    <div className="amc-modal-item">
                      <h1 className="amc-modal-title">
                        {LOCALES.features} {LOCALES.apiPresent}
                      </h1>
                      <h4 className="amc-modal-sub-title">
                        {props.modaldata.DISPLAY_NAME}
                      </h4>
                      <p className="amc-modal-desc">
                        {props.modaldata.DESCRIPTION}
                      </p>
                    </div>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div className="amc-modal-item">
                      <h1 className="amc-modal-title">
                        {LOCALES.features} {LOCALES.apiPresent}
                      </h1>
                      <div
                        className="skeleton image-container"
                        style={{
                          backgroundImage: `url("images/screenshots/jpgs/${(
                            props.modaldata?.CODE || props.modaldata?.code
                          ).toLowerCase()}_1.jpg")`,
                        }}
                      ></div>
                    </div>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div className="amc-modal-item">
                      <h1 className="amc-modal-title">
                        {LOCALES.features} {LOCALES.apiPresent}
                      </h1>
                      <div
                        className="skeleton image-container"
                        style={{
                          backgroundImage: `url("images/screenshots/jpgs/${(
                            props.modaldata?.CODE || props.modaldata?.code
                          ).toLowerCase()}_2.jpg")`,
                        }}
                      ></div>
                    </div>
                  </Carousel.Item>
                  {props.modaldata?.CODE === "GENERATE_UNIT_TEST" && (
                    <Carousel.Item>
                      <div className="amc-modal-item">
                        <h1 className="amc-modal-title">
                          {LOCALES.features} {LOCALES.apiPresent}
                        </h1>
                        <div
                          className="skeleton image-container"
                          style={{
                            backgroundImage: `url("images/screenshots/jpgs/${(
                              props.modaldata?.CODE || props.modaldata?.code
                            ).toLowerCase()}_3.jpg")`,
                          }}
                        ></div>
                      </div>
                    </Carousel.Item>
                  )}
                </Carousel>
              )}
            </>
          </Modal.Body>
        )}
      </Modal>
    </>
  );
};

export default AMCModal;
