import axios from 'axios';

const SERVER_URL = process.env.REACT_APP_API_BASE_URL;


/**
 * Download Plugin
 * @returns Plugin;
 */
const downloadPlugin = async (type: string) => {
  try {
    const response = await axios.get(`${SERVER_URL}/genai/auth/download/plugin/${type}`);
    return response.data;
  } catch (err) {}
};

/**
 * Get FAQs List
 * @returns FAQs List;
 */
const getFAQsList = async () => {
  try {
    const response = await axios.get(`${SERVER_URL}/public/data/FAQs`);
    return response.data;
  } catch (err) {}
};



/**
 * Get Differentiators data.
 * @returns differentiators List.
 */
const getDifferentiatorsData = async () => {
  try {
    const response = await axios.get(`${SERVER_URL}/public/data/toolComparison`);
    return response.data;
  } catch (err) {}
};

/**
 * Get All Features List
 * @returns features List;
 */
const getFeaturesList = async () => {
  try {
    const response = await axios.get(`${SERVER_URL}/public/data/amcFeature`);
    return response.data;
  } catch (err) {}
};


/**
 * Get Languages List
 * @returns Languages List.
 */
const getLanguagesList = async () => {
  try {
    const response = await axios.get(`${SERVER_URL}/public/data/variants`);
    return response.data;
  } catch (err) {
    console.error(err);
  }
}

/**
 * Get All Models List
 * @returns Models List.
 */
const getModelsList = async () => {
  try {
    const response = await axios.get(`${SERVER_URL}/public/data/models/all`);
    return response.data;
  } catch (err) {}
};


const HomeService = {
  downloadPlugin,
  getFAQsList,
  getDifferentiatorsData,
  getFeaturesList,
  getLanguagesList,
  getModelsList
};

export default HomeService;