import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useNavigate } from "react-router-dom";
import { LOCALES } from "../../constants/locales";
import "./Styles/AMCFooter.scss";
const SERVER_URL = process.env.REACT_APP_API_BASE_URL;

function AMCFooter() {
  const navigate = useNavigate();

  const handleFooterClick = () => {
    navigate("/register");
  };

  const openProduct = () => {
    window.open(
      `${SERVER_URL}/files/amc_code_assist_product.pdf`,
      "_blank",
      "noopener,noreferrer"
    );
  };

  const openRoadMap = () => {
    window.open(
      `${SERVER_URL}/images/amc_code_assist_roadmap.png`,
      "_blank",
      "noopener,noreferrer"
    );
  };

  const openFeedBack = () => {
    window.open(
      "https://deloittesurvey.deloitte.com/Community/se/3FC11B2600C36E37",
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <Container fluid className="f-container">
      <Row>
        <Col xl={6}>
          <div className="pl-4">
            <img
              src="images/deloitte-bg-white.svg"
              alt="amc-code-assisit"
              className="mb-1"
            />
          </div>
          <div className="footer-desc">
            <p>About Deloitte</p>

            <p className="mb-0">
              Deloitte refers to one or more of Deloitte Touche Tohmatsu
              Limited, a UK private company limited by guarantee (“DTTL”), its
              network of member firms, and their related entities. DTTL and each
              of its member firms are legally separate and independent entities.
              DTTL (also referred to as “Deloitte Global”) does not provide
              services to clients. In the United States, Deloitte refers to one
              or more of the US member firms of DTTL, their related entities
              that operate using the “Deloitte” name in the United States and
              their respective affiliates. Certain services may not be available
              to attest clients under the rules and regulations of public
              accounting. Please see www.deloitte.com/about to learn more about
              our global network of member firms.
            </p>
            <p>
              Copyright © 2024 Deloitte Development LLC. All rights reserved.
            </p>
          </div>
        </Col>

        <Col xl={6}>
          <Row>
            <Col xl={4}></Col>
            <Col
              xl={4}
              className="text-grey sub-container align-items-baseline"
            >
              <Button
                variant="link"
                className="text-decoration-none"
                href="#title-banner-downloads"
              >
                {LOCALES.downloads}
              </Button>
              <Button
                href="#features"
                variant="link"
                className="pt-4 text-decoration-none"
              >
                {LOCALES.features}
              </Button>
              <Button
                href="#models"
                variant="link"
                className="pt-4 text-decoration-none"
              >
                {LOCALES.models}
              </Button>
              <Button
                href="#tutorials"
                variant="link"
                className="pt-4 text-decoration-none"
              >
                {LOCALES.tutorials}
              </Button>
              <Button
                href="#differentiator"
                variant="link"
                className="pt-4 text-decoration-none"
              >
                <span>
                  {LOCALES.amcCodeAssist} {LOCALES.advantage}
                </span>
              </Button>
            </Col>
            <Col
              xl={4}
              className="text-grey sub-container align-items-baseline"
            >
              <Button
                variant="link"
                className="text-decoration-none"
                onClick={openProduct}
              >
                {LOCALES.product}
              </Button>
              <Button
                variant="link"
                className="pt-4 text-decoration-none"
                onClick={openRoadMap}
              >
                {LOCALES.roadMap}
              </Button>
              <Button
                variant="link"
                className="pt-4 text-decoration-none"
                onClick={handleFooterClick}
              >
                {LOCALES.registration}
              </Button>
              <Button
                variant="link"
                className="pt-4 text-decoration-none"
                onClick={openFeedBack}
              >
                {LOCALES.feedback}
              </Button>
              <Button
                href="mailto:amccodeassist@deloitte.com"
                variant="link"
                className="pt-4 text-decoration-none"
              >
                {LOCALES.email}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default AMCFooter;
