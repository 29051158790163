import Offcanvas from 'react-bootstrap/Offcanvas';
import FAQs from '../../FAQs/FAQs';
import './Styles/AMCOffCanvas.scss';
import AssistAdvantage from '../../AssistAdvantage/AssistAdvantage';

type AMCOffCanvasProps = {
  setAMCOffCanvasShow: (show: boolean) => void;
  type: string;
};

const AMCOffCanvas = ({ setAMCOffCanvasShow, type }: AMCOffCanvasProps) => {
  return (
    <Offcanvas show={true} className="offcanvas-xl scroll-y amc-offcanvas-container" onHide={() => setAMCOffCanvasShow(false)} placement='bottom' >
      <Offcanvas.Body>
        {type === 'FAQ' && <FAQs setFaqsModalShow={setAMCOffCanvasShow} />}
        {type === 'DIFFERNTIATIROS' && <AssistAdvantage setDifferentiatorsCanvasShow={setAMCOffCanvasShow} />}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default AMCOffCanvas;
