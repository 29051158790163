import React from "react";
import "./Styles/PageNotFound.scss";
import { LOCALES } from "../../constants/locales";

function PageNotFound() {
  return (
    <div className="flex-center-container">
      <div className="page-not-found">{LOCALES.pageNotFound}</div>
    </div>
  );
}

export default PageNotFound;
