import { useEffect, useState } from 'react';
import CloseButton from 'react-bootstrap/CloseButton';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';
import { LOCALES } from '../../constants/locales';
import HomeService from '../../services/homeService';
import './Styles/AssistAdvantage.scss';

const AssistAdvantage = ({ setDifferentiatorsCanvasShow }: { setDifferentiatorsCanvasShow: (show: boolean) => void; }) => {

  const [differentiatorsData, setDifferentiatorsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true)

  const getDifferentiatorsData = async () => {
    const data = await HomeService.getDifferentiatorsData();
    setDifferentiatorsData(data || []);
    setIsLoading(false);
  };

  function createMarkup(dirty: any) {
    return { __html: dirty };
  }

  useEffect(() => {
    setIsLoading(true);
    getDifferentiatorsData();
  }, []);

  return (
    <Container fluid className="adv-container" id="differentiator">
      <div className="flex justify-content-between align-items-center title-header">
        <div className="flex align-items-center">
          <img
            src="images/adv-pointer.svg"
            alt="divider-one"
            className="mlr-3"
          />
          <h1 className="text-white text-weight-bold">{LOCALES.amcCodeAssist} {LOCALES.advantage}</h1>
        </div>
        <CloseButton className='btn-close-white' onClick={() => setDifferentiatorsCanvasShow(false)} />
      </div>
      <div>
        {isLoading && <div className='loader-container'><Spinner animation="border" /></div>}
        {!isLoading && <Table responsive borderless className='mb-0'>
          <thead className="table-head">
            <tr>
              <th>Features</th>
              <th>Amazon Q</th>
              <th>Amazon Code Whisper</th>
              <th>Github Copilot</th>
              <th>Turbo Code</th>
              <th>Amc Code Assist</th>
            </tr>
          </thead>
          <tbody className="table-body">
            {(differentiatorsData.length && !isLoading) && differentiatorsData.map((amc: any) => (
              <tr>
                <td>
                  <b>{amc.FEATURE}</b>
                </td>
                <td dangerouslySetInnerHTML={createMarkup(amc.AMAZON_Q)}></td>
                <td dangerouslySetInnerHTML={createMarkup(amc.AMAZON_CODE_WHISPER)}></td>
                <td dangerouslySetInnerHTML={createMarkup(amc.GITHUB_COPILOT)}></td>
                <td dangerouslySetInnerHTML={createMarkup(amc.TURBOCODE)}></td>
                <td dangerouslySetInnerHTML={createMarkup(amc.AMC_CODE_ASSIST)}></td>
              </tr>
            ))}
          </tbody>
        </Table>}
      </div>
    </Container>
  );
};

export default AssistAdvantage;
