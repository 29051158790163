
export const LOCALES = {
  "about": "About",
  "adopeAEM": "Adope AEM",
  "advantage": "Advantage",
  "amcCodeAssist": "AMC Code Assist",
  "apiPresent": "(API Present)",
  "backToTop": "Back to Top",
  "contactUs": "Contact Us",
  "downloads": "Downloads",
  "email": "Email",
  "eclipse": "Eclipse",
  "faq": "FAQs",
  "features": "Features",
  "feedback": "Feedback",
  "frequentlyAskedQuestions": "Frequently Asked Questions",
  "getInTouchWithUs": "Get in touch with us:",
  "intellij": "Intellij",
  "intellijPluginInstallationGuide": "IntelliJ Plugin Installation Guide",
  "java": "Java",
  "knowMore": "Know More",
  "languages": "Languages",
  "models": "Models",
  "needHelp": "Need Help ?",
  "noRecordsFound": "No Records Found",
  "product": "Product",
  "reviewRequests": "Review Requests",
  "roadMap": "Roadmap",
  "registration": "Registration",
  "sapHybris": "SAP Hybris",
  "stillHaveQuestions": "Still have questions?",
  "trailOrRegistration": "Trial | Registration",
  "tutorials": "Tutorials",
  "fullName": "Full Name",
  "deloitteEmail": "Deloitte Email",
  "managerEmail": "Manager Email",
  "projectWbs": "Project WBS",
  "iAgree": "I agree to the",
  "termsAndConditions": "Terms and Conditions",
  "submit": "Submit",
  "vsCode": "Visual Studio Code",
  "averageCostPerUserPerMonth": "Average Cost per user per Month",
  "tokensLimitPerInteraction": "Tokens Limit per interaction",
  "pricingPlan": "Pricing Plan",
  "pageNotFound": "Page Not Found",
  "requestAccess": "Request Access"
}