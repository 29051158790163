import Modal from "react-bootstrap/Modal";

type ModalProps = {
  show: boolean;
  onHide: () => void;
  // title: string;
  children: React.ReactNode;
};

const SuccessModal: React.FC<ModalProps> = ({ show, onHide, children }) => {
  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      centered
      className="amc-modal-container"
    >
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
};

export default SuccessModal;
