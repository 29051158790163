import Button from "react-bootstrap/esm/Button";
import "./Styles/BackToTop.scss";

const BackToTop = ({ color }: { color?: string }) => {
  const handleScrollUp = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="back-top-btn">
      <Button
        variant="outline-light"
        className="back-to-top"
        onClick={handleScrollUp}
      >
        <svg
          width="25"
          height="16"
          viewBox="0 0 29 16"
          fill={color}
          className="up-arrow"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M-8.55536e-07 13.5369C-8.72304e-07 13.9205 0.147578 14.3041 0.44888 14.5978C1.05148 15.1852 2.02303 15.1852 2.62564 14.5978L13.1651 4.32424C13.6693 3.83874 14.481 3.83874 14.9852 4.32424L25.5246 14.5978C26.1272 15.1852 27.0988 15.1852 27.7014 14.5978C28.304 14.0104 28.304 13.0633 27.7014 12.4759L17.1619 2.20241C15.4648 0.548091 12.6977 0.548091 10.9945 2.20241L0.455031 12.4759C0.153728 12.7696 0.0061495 13.1532 0.00614948 13.5369L-8.55536e-07 13.5369Z"
            fill="white"
          />
        </svg>
        {/* { LOCALES.backToTop } */}
      </Button>{" "}
    </div>
  );
};

export default BackToTop;
