import { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { LOCALES } from "../../constants/locales";
import HomeService from "../../services/homeService";
import AmcCard from "../common/Card/AmcCard";
import FlowLine from "../common/FlowLine/FlowLine";
import "./Styles/Models.scss";
import { SERVICES } from "../../enums/model-services.enum";

function Models() {
  const [models, setModels] = useState([]);
  const modelsList: any = [];

  const getModels = async () => {
    try {
      const response = await HomeService.getModelsList();
      if (response.length) {
        response.forEach((item: any) => {
          switch (item.DISPLAY_NAME) {
            case SERVICES.AWS:
              modelsList[1] = item;
              break;
            case SERVICES.GOOGLE:
              modelsList[0] = item;
              break;
            case SERVICES.AZURE:
              modelsList[2] = item;
              break;
          }
        });

        setModels(modelsList);
      }
    } catch (err) {
      setModels([]);
      console.error(err);
    }
  };

  useEffect(() => {
    getModels();
  }, []);

  return (
    <Container fluid className="model-container relative" id="models">
      <img
        src="images/models-dots.svg"
        alt="feature"
        className="pl-0 absolute"
      />
      <Row>
        <Col xl={12}>
          <div className="pr-7">
            <div className="flex row-reverse align-items-center">
              <img src="images/AI-icon.svg" alt="user" className="menu-info" />
              <h1 className="text-white text-weight-bold">
                {LOCALES.models} {LOCALES.apiPresent}
              </h1>
            </div>
          </div>
        </Col>
        <Col xl={1}></Col>
      </Row>
      <Row>
        <Col xl={12}>
          <div className="pr-7">
            <div className="flex row-reverse">
              <div className="cards-list">
                {models.map((model, index) => (
                  <AmcCard
                    key={`model-${index}`}
                    details={model}
                    type="models"
                  />
                ))}
              </div>
            </div>
          </div>
        </Col>
        <Col xl={12}></Col>
      </Row>
      <div className="display-inline-flex">
        <FlowLine
          className="model-vertical"
          isDotShow
          dotClass="right-check-dot"
        />
        <FlowLine className="model-vertical-2" isDotShow={false} />
      </div>
    </Container>
  );
}

export default Models;
