import Container from 'react-bootstrap/esm/Container';
import './Styles/Footer.scss';

const Footer = () => {

  const cookieSettingsClick = () => {
    document.cookie = "OptanonConsent=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "OptanonAlertBoxClosed=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    (window as any).OneTrust.ToggleInfoDisplay();
  }

  return (
    <footer className='footer'>
      <Container fluid>
        <div className="container-for-links">
          <a className="prc_btn_footer" href="https://cookienotice.deloitte.com" target="_blank" rel="noreferrer">Cookies</a>
          <a className="cookie_settings_btn_footer" onClick={cookieSettingsClick}>Cookie Settings</a>
        </div>
      </Container>
    </footer>
  );
}

export default Footer;