
export const NAV_LINKS = [
  {
    id: 1,
    title: 'Languages',
    href: '#languages'
  },
  {
    id: 2,
    title: 'Features',
    href: '#features'
  },
  {
    id: 3,
    title: 'Models',
    href: '#models'
  },
  {
    id: 4,
    title: 'Tutorials',
    href: '#tutorials'
  },
  {
    id: 5,
    title: 'Differentiators',
    href: '#differentiator'
  },
  {
    id: 6,
    title: 'About',
    href: '#about'
  }
];