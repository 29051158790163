import Container from "react-bootstrap/Container";
import "./Styles/SlideShow.scss";
import Carousel from "react-bootstrap/Carousel";

function SlideShow() {
  const images: any = [
    { path: "/images/jpgs/slide-show-one.jpg" },
    { path: "/images/jpgs/slide-show-two.jpg" },
    { path: "/images/jpgs/slide-show-three.jpg" },
    { path: "/images/jpgs/slide-show-four.jpg" },
  ];

  return (
    <Container fluid className="slide-show-container">
      <img
        src="images/divider-one.svg"
        alt="divider-one"
        className="divider-one"
      />
      <div>
        <Carousel>
          {images.map((img: any, index: number) => (
            <Carousel.Item key={`slide-item-${index}`}>
              <div
                className="slide-show"
                style={{
                  backgroundImage: `url(${img.path})`,
                }}
              ></div>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </Container>
  );
}

export default SlideShow;
