import Container from "react-bootstrap/Container";
import Accordion from "react-bootstrap/Accordion";

import "./Styles/FAQs.scss";
import { LOCALES } from "../../constants/locales";
import CloseButton from "react-bootstrap/CloseButton";
import { useEffect, useState } from "react";
import HomeService from "../../services/homeService";
import { Spinner } from "react-bootstrap";

function createMarkup(dirty: any) {
  return { __html: dirty };
}

const FAQs = ({
  setFaqsModalShow,
}: {
  setFaqsModalShow: (show: boolean) => void;
}) => {
  const [faqsList, setfaqsList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getFAQsList = async () => {
    try {
      const data = await HomeService.getFAQsList();
      setfaqsList(data);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getFAQsList();
  }, []);

  return (
    <>
      <Container fluid className="faq-container">
        <>
          <div className="align-items-center center flex flex-column relative">
            <div>
              <h2>{LOCALES.frequentlyAskedQuestions}</h2>
            </div>
            <div className="p-3">
              <h5>{LOCALES.needHelp}</h5>
            </div>
            <CloseButton
              className="absolute tr-0"
              onClick={() => setFaqsModalShow(false)}
            />
          </div>
          {faqsList.length ? (
            <>
              <div>
                <Accordion>
                  {faqsList.map((faq: any) => (
                    <Accordion.Item eventKey={faq.CODE}>
                      <Accordion.Header>{faq.QUESTION}</Accordion.Header>
                      <Accordion.Body>
                        <div
                          dangerouslySetInnerHTML={createMarkup(faq.ANSWER)}
                        ></div>
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </div>
              <div className="faq-footer">
                <h5 className="text-white">{LOCALES.stillHaveQuestions}</h5>
                <span className="text-white">
                  {LOCALES.getInTouchWithUs}:{" "}
                  <a
                    className="text-decoration-none text-light"
                    href="mailto:amccodeassist@deloitte.com"
                  >
                    amccodeassist@deloitte.com
                  </a>
                </span>
              </div>
            </>
          ) : (
            <></>
          )}
        </>
      </Container>
      {/* Loader */}
      {isLoading ? (
        <div className="registration-loader">
          <Spinner animation="border" />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default FAQs;
