import "bootstrap/dist/css/bootstrap.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Footer from "./components/Footer/Footer";
import Home from "./pages/Home/Home";
import Registration from "./pages/Registration/Registration";
import ReviewRequest from "./pages/ReviewRequests/ReviewRequests";
import PageNotFound from "./pages/PageNotFound/ReviewRequestor";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="*" element={<PageNotFound />} />
        <Route path="/register" element={<Registration />}></Route>
        <Route path="/review" element={<ReviewRequest />}></Route>
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
