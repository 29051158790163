import { useEffect, useState } from "react";
import AMCFooter from "../../components/AMCFooter/AMCFooter";
import About from "../../components/About/About";
import Differentiators from "../../components/Differentiators/Differentiators";
import Features from "../../components/Features/Features";
import LanguagesBanner from "../../components/LanguagesBanner/LanguagesBanner";
import Models from "../../components/Models/Models";
import SlideShow from "../../components/SlideShow/SlideShow";
import TitleBanner from "../../components/TitleBanner/TitleBanner";
import Tutorials from "../../components/Tutorials/Tutorials";
import BackToTop from "../../components/common/BackToTop/BackToTop";
import NavbarNavigation from "../../components/common/Navigation/Navigation";
import "./Styles/Home.scss";

const Home = () => {
  const [isScrollToTopVisible, setIsScrollToTopVisible] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  const handlevisibleBtn = () => {
    const position = document.documentElement.scrollTop;
    setScrollPosition(position);
    if (scrollPosition > 30) {
      setIsScrollToTopVisible(true);
    } else {
      setIsScrollToTopVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handlevisibleBtn);
  });

  return (
    <main>
      <div className="jumbotron jumbotron-fluid">
        {isScrollToTopVisible ? <BackToTop /> : <></>}
        <NavbarNavigation />
        <TitleBanner />
        <SlideShow />
        <LanguagesBanner />
        <Features />
        <Models />
        <Tutorials />
        <Differentiators />
        <About />
        <AMCFooter />
      </div>
    </main>
  );
};

export default Home;
