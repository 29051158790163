import axios from "axios";

const SERVER_URL = process.env.REACT_APP_API_BASE_URL;

/**
 * Requests access for the user
 */
const registerUser = async (userdata: any) => {
  try {
    const response = await axios.post(
      `${SERVER_URL}/genai/auth/request/access`,
      userdata
    );
    return response.data;
  } catch (err) {}
};

/**
 * verifies if User is a deloitte User using Deloitte Single Sign-on
 * @returns User details like userEmail and userName
 */
const verifyUser = async () => {
  try {
    const response = await axios.get(
      `${SERVER_URL}/genai/auth/registration/verify/sso/token`
    );
    return response.data;
  } catch (err) {}
};

//GET
export const getUsers = async () => {
  try {
    const res = await axios.get(`${SERVER_URL}/genai/auth/get/users`);
    return res.data;
  } catch (err: any) {
    if(err.response.status === 401) {
      window.location.href = `${SERVER_URL}/genai/auth/admin/login`;
    }
    console.error(err);
  }
};

// VERIFY/POST
export const verifyUserAccess = async (resourceData: any, setLoading: any) => {
  try {
    console.log("on click of apporve & reject", { resourceData });
    const res = await axios.post(`${SERVER_URL}/genai/auth/verify/access`, {
      userName: resourceData.user_name,
      userId: resourceData.user_id,
      userEmail: resourceData.user_email,
      approvalStatus: resourceData.actionItem,
      comments: resourceData.reasonText,
      trialEndDays: resourceData.trialEndDays,
    });

    if (res) {
      setLoading(false);
      window.location.reload();
    }
  } catch (error) {
    console.log(error);
  }
};

export const navigateToSSO = () => {
  window.location.href = `${SERVER_URL}/genai/auth/registration`;
}

const AuthService = {
  registerUser,
  verifyUser,
  getUsers,
  verifyUserAccess,
  navigateToSSO
};

export default AuthService;
