import { useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { LOCALES } from "../../constants/locales";
import AMCOffCanvas from "../common/AMCOffCanvas/AMCOffCanvas";
import FlowLine from "../common/FlowLine/FlowLine";
import "./Styles/About.scss";

function About() {
  const [isFaqsModalShow, setFaqsModalShow] = useState(false);

  const feedBack = () => {
    window.open(
      "https://deloittesurvey.deloitte.com/Community/se/3FC11B2600C36E37",
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <Container fluid className="about-container relative" id="about">
      <FlowLine className="" isDotShow />.
      <img
        src="images/about-dots-bg.svg"
        className="absolute dots"
        alt="feature"
      />
      <Row>
        <Col>
          <div className="pl-7">
            <div className="flex">
              <img
                src="images/about-setting.svg"
                alt="user"
                className="icon-info"
              />
              <h1 className="text-white text-weight-bold">{LOCALES.about}</h1>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xl={6} className="relative">
          <div className="pl-7">
            <div className="flex">
              <p className="text-white content">
                <span>
                AMC Code Assist is an innovative AI-powered plugin designed to
                seamlessly integrate with your development environment,
                transforming the way you code. By harnessing the most advanced
                AI technologies, AMC Code Assist offers real-time coding
                assistance, error correction, and intelligent code completion
                features that significantly boost productivity and efficiency.
                </span>

                <br/>
                <br/>

                <span>
                Elevate your coding experience with AMC Code Assist—where
                artificial intelligence meets human innovation to redefine
                software development
                </span>
              </p>
            </div>
          </div>
          <Row>
            <Col xl={12}>
              <div className="flex justify-content-between">
                <div></div>
                <div>
                  <img src="images/system-settings-right.svg" alt="feature" />
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col xl={6}>
          <div className="about-info-cont">
            <div className="flex mt-8">
              <div className="email-icon">
                <img src="images/email-icon.svg" alt="feature" />
              </div>
              <div className="text-white contact-info">
                <p className="f-500">{LOCALES.contactUs}</p>
                <p>
                  <a
                    className="text-decoration-none text-light"
                    href="mailto:amccodeassist@deloitte.com"
                  >
                    amccodeassist@deloitte.com
                  </a>
                </p>
              </div>
            </div>
            <Col className="flex pt-6 justify-content-between">
              <button className="flex border tag" onClick={() => feedBack()}>
                <img src="images/feedback.svg" alt="feature" className="pt-2" />
                <div>
                  <span className="text-white">{LOCALES.feedback}</span>
                </div>
              </button>
              <button
                className="flex border tag"
                onClick={() => setFaqsModalShow(true)}
              >
                <img src="images/faq.svg" alt="feature" />
                <div>
                  <span className="text-white">{LOCALES.faq}</span>
                </div>
              </button>
            </Col>
          </div>
        </Col>
      </Row>
      {isFaqsModalShow && (
        <AMCOffCanvas type="FAQ" setAMCOffCanvasShow={setFaqsModalShow} />
      )}
    </Container>
  );
}

export default About;
