import { lazy, Suspense, useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { useLocation } from "react-router-dom";
// import useIsAuthenticated from 'react-auth-kit';
import SearchComponent from "../../components/Search/Search";
// import { getUsers } from '../api';
import Tippy from "@tippyjs/react";
import { Spinner } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { BsFillPersonCheckFill, BsFillPersonXFill } from "react-icons/bs";
import { Circles } from "react-loader-spinner";
import "tippy.js/dist/tippy.css";
import { LOCALES } from "../../constants/locales";
import AuthService from "../../services/authService";
import "./Styles/ReviewRequests.scss";

//Dynamic Imports
const RequestSuccess = lazy(() => import("../RequestSuccess"));

const ReviewRequests = () => {
  // const isAuthenticated = useIsAuthenticated();
  const { search } = useLocation();
  const [requestData, setRequestData] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [approveRequest, setApproveRequest] = useState(false);
  const [rejectRequest, setRejectRequest] = useState(false);
  const [requestRecordData, setRequestRecordData] = useState({} as any);
  const [queryParams, setQueryParams] = useState();
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const getUsers = async () => {
    try {
      const response: any = await AuthService.getUsers();
      setRequestData(response || []);
      setSearchResults(response || []);
      setIsLoading(false);
    } catch (err) {
      setRequestData([]);
      setSearchResults([]);
      setIsLoading(false);
      console.error(err);
    }
  };

  useEffect(() => {
    getUsers();
    return () => {};
  }, []);

  useEffect(() => {
    if (requestData) {
      const getQueryParam = new URLSearchParams(search);
      const user: any = getQueryParam.get("user") || "";
      setQueryParams(user);
      if (user !== "") {
        const resultsArray = requestData.filter((data: any) =>
          data.user_email.toLowerCase().includes(user)
        );
        setSearchResults(resultsArray);
      }
    }
  }, [requestData, search, queryParams]);

  //function to remove object based on Duplication submit
  const removeDuplicateObjects = (array: any, property: any) => {
    const seen = new Set();
    return array
      .filter((item: any) => {
        const key = item[property];
        if (!seen.has(key)) {
          seen.add(key);
          return true;
        }
        return false;
      })
      .sort((a: any, b: any) => {
        let reqA = a.approval_status.toLowerCase(),
          notifiedA = a.is_trial_end_notified,
          notifiedB = a.is_trial_end_notified,
          reqB = b.approval_status.toLowerCase();
        return reqA === "initiated" && reqB !== "initated"
          ? -1
          : reqA === "approved" &&
            notifiedA &&
            (reqB !== "approved" || reqB === "rejected")
          ? 0
          : reqA === "rejected" && (reqB === "approved" || reqB === "initiated")
          ? 0
          : -1;
      });
  };

  const filteredArray = removeDuplicateObjects(searchResults, "user_email");

  const handleClose = () => {
    setApproveRequest(false);
    setRejectRequest(false);
    setRequestRecordData({});
  };

  const handleApprovedIconClick = () => {
    setApproveRequest(true);
  };

  const handleRejectedIconClick = () => {
    setRejectRequest(true);
  };

  const handleSubmitRequest = (rowData: any, actionItem: any) => {
    setRequestRecordData({ ...rowData, actionItem });
  };

  const trailDayConversion = (date: any) => {
    const getTrailDate = new Date(date);
    const formatDate = `${getTrailDate.getFullYear()}-${
      getTrailDate.getMonth() + 1
    }-${getTrailDate.getDate()}`;
    const formatMonth = new Date(formatDate).toLocaleString("default", {
      month: "short",
    });
    return `${getTrailDate.getDate()} ${formatMonth} ${getTrailDate.getFullYear()}`;
  };

  if (loading) {
    return (
      <div className="loaderContainer">
        <Circles color="Red" height={50} width={50} />
        Submitting the data...
      </div>
    );
  }

  return (
    <Container fluid className="review-container" id="review-request">
      {isLoading ? (
        <div className="review-loader-container">
          <Spinner animation="border" />
        </div>
      ) : (
        <>
          <div className="flex justify-content-between align-items-center title-header">
            <div className="flex align-items-center">
              <img
                src="images/adv-pointer.svg"
                alt="divider-one"
                className="mlr-3"
              />
              <h1 className="text-white text-weight-bold">
                {LOCALES.reviewRequests}
              </h1>
            </div>
            <SearchComponent
              requestData={requestData}
              setSearchResults={setSearchResults}
            />
          </div>
          <div>
            {
              <Table responsive borderless className="mb-0">
                <thead className="table-head">
                  <tr>
                    <th>Sr #</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Manager Email</th>
                    <th>WBS Code</th>
                    <th>Trail Start Date</th>
                    <th>Trail End Date</th>
                    <th>Trail End Notified</th>
                    <th>Request Status</th>
                    <th>Action Item</th>
                    <th>Comments / Feedback</th>
                  </tr>
                </thead>
                <tbody className="table-body">
                  {!!filteredArray.length &&
                    filteredArray.map((row: any, index: number) => (
                      <tr
                        key={row.user_id}
                        className={
                          !row.is_trial_end_notified &&
                          row.approval_status === "APPROVED"
                            ? `user-notified`
                            : ""
                        }
                      >
                        <td>{index + 1}</td>
                        <td>{row.user_name}</td>
                        <td>{row.user_email}</td>
                        <td>{row.manager_email}</td>
                        <td>{row.project_wbs}</td>
                        <td>
                          {row.trial_start_date &&
                            trailDayConversion(row.trial_start_date)}
                        </td>
                        <td>
                          {row.trial_end_date &&
                            trailDayConversion(row.trial_end_date)}
                        </td>
                        <td className="status-td">
                          {row.is_trial_end_notified ? "YES" : "NO"}
                        </td>
                        <td className="status-td">{row.approval_status}</td>
                        <td className="status-td">
                          <div className="td-approved">
                            {row.approval_status === "APPROVED" ? (
                              <div>
                                <BsFillPersonCheckFill
                                  style={{ color: "#171b1f91", width: "26px" }}
                                />
                              </div>
                            ) : (
                              <Tippy
                                content="Approve the request"
                                placement="top"
                              >
                                <div
                                  onClick={() =>
                                    handleSubmitRequest(row, "APPROVED")
                                  }
                                >
                                  <BsFillPersonCheckFill
                                    onClick={handleApprovedIconClick}
                                    style={{ color: "rgb(54 194 54)" }}
                                    className="approved-icon"
                                  />
                                </div>
                              </Tippy>
                            )}
                            {row.approval_status === "REJECTED" ? (
                              <div>
                                <BsFillPersonXFill
                                  style={{ color: "#171b1f91", width: "26px" }}
                                />
                              </div>
                            ) : (
                              <Tippy
                                content="Reject the request"
                                placement="bottom"
                              >
                                <div
                                  onClick={() =>
                                    handleSubmitRequest(row, "REJECTED")
                                  }
                                >
                                  <BsFillPersonXFill
                                    onClick={handleRejectedIconClick}
                                    style={{ color: "#ff0000" }}
                                    className="rejected-icon"
                                  />
                                </div>
                              </Tippy>
                            )}
                          </div>
                        </td>
                        <td>{row.comments}</td>
                      </tr>
                    ))}
                  {searchResults.length === 0 && (
                    <tr>
                      <td colSpan={11} className="noResults">
                        {LOCALES.noRecordsFound}
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            }
          </div>
          {approveRequest && (
            <Suspense fallback={<h2>Loading...</h2>}>
              <RequestSuccess
                show={approveRequest}
                handleClose={handleClose}
                userRequestData={requestRecordData}
                titleContent="Approve the Request Status?"
                bodyContent={`Would you approve the request for <b>${requestRecordData?.user_name?.toUpperCase()}</b>`}
                actionItem="approve"
                handleSubmitRequest={handleSubmitRequest}
                setLoading={setLoading}
              />
            </Suspense>
          )}
          {rejectRequest && (
            <Suspense fallback={<h2>Loading...</h2>}>
              <RequestSuccess
                show={rejectRequest}
                handleClose={handleClose}
                userRequestData={requestRecordData}
                titleContent="Reject the Request Status?"
                bodyContent={`Are you sure you want to reject the request for <b>${requestRecordData?.user_name?.toUpperCase()}<b>`}
                actionItem="reject"
                handleSubmitRequest={handleSubmitRequest}
                setLoading={setLoading}
              />
            </Suspense>
          )}
        </>
      )}
    </Container>
  );
};

export default ReviewRequests;
